/*	--------------------------------------------------
	--------------------------------------------------
		Widgets :
	propriétés générales, texte, actu, rss,
	banniere, calendrier, raccourcis, météo, sondage,
	recommander le site, newsletter, publicité

--------------------------------------------------
--------------------------------------------------*/


/* commons */
.box {}
.no-title{
	.box--title{
		display: none;
	}
}
.box--title {}

/* text (/boxes/text/box_text.html) */
.box-txt {}

/* shortcuts header (/boxes/shortcut/header_shortcut.html) */
.box-header-links {}
.box-header-links--list {}
.box-header-links--item {}
.box-header-links--link, .box-header-links--link:visited {}

/* shortcuts aside (/boxes/shortcut/tools_shortcut.html) */
.box-aside-links {}
.box-aside-links--list {}
.box-aside-links--item {}
.box-aside-links--link, .box-aside-links--link:visited {}

/* shortcuts footer (/boxes/shortcut/footer_shortcut.html) */
.box-footer-links {}
.box-footer-links--list {}
.box-footer-links--item {}
.box-footer-links--link, .box-footer-links--link:visited {}

/* last elements (/boxes/last_elements/last_elements.html) */
.box-last-elts {}
.box-last-elts--list {}
.box-last-elts--item {}
.box-last-elts--article {}
.box-last-elts--title {}
.box-last-elts--date {}
.box-last-elts--img {}
.box-last-elts--desc {}
.box-last-elts--link, .box-last-elts--link:visited {}

/* newsletter (/newsletters/box_newsletters.html) */
.box-nl {}
.box-nl--desc {}
.box-nl--form {}
.box-nl--label {}
.box-nl--input {}
.box-nl--submit {}


/* search (/search/box_search.html) */
.box-search {}
.box-search--form {}
.box-search--label {}
.box-search--input {}
.box-search--submit, .box-search--submit:visited {}
.box-search--advanced, .box-search--advanced:visited {}

/* box-social (/z-templates/social.tmpl.html) */
.box-social {}
.box-social--item {}
.box-social--link, .box-social--link:visited {}
.box-social--facebook {}
.box-social--twitter {}
.box-social--google {}


/* box-coordinates (/z-templates/coordonnees_38.tmpl.html) FOOTER */

.box-coordinates {
	background-color: rgba(0,46,87,.85);
	color: #fff;
	margin-top: 35px;
	padding: 30px 10px 55px 10px ;

	a {
		color: #fff;
	}

	.h3 {
		margin: 0;
	}

	.title {
		margin-bottom: 35px;
	}
}

.box-coordinates--numbers { margin-bottom: 20px; }

.box-coordinates--icon {
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
	border: 2px solid @color3;
    border-radius: 50%;
    padding: 17px 5px;
    width: 55px;
    height: 55px;
    position: relative;
	i {
		position: absolute;
		top: -3px;
		left: 0;
		text-align: center;
		right: 0;
		padding: 16px 0;
		color: @color3;
		font-size: 30px;

		&.icon-picto-formulaire { font-size: 25px; }
	}
}

.box-coordinates--text { display: inline-block; }
@media (max-width:@screen-phone) {
	.box-coordinates--text-answer { width: 70%; }
}
.box-coordinates--form a { color: @color3; }

/* box-coordinates (/z-templates/coordonnees_43.tmpl.html) HEADER Formulaire */

.box-coordinates--header {
	background-color: transparent;
	padding: 0!important;

	.box-coordinates--text,
	.box-coordinates--link  {
		color: @text-color;
		text-decoration: none;
		font-size: 18px;
	}

	.box-coordinates--icon { border-color: @color2; }
	.box-coordinates--icon i { color: @color2; }
}



/* mentions légales (/z-templates/mentions_legales_39.tmpl.html) */
.box-footer--mentions {
	color: #fff;
	font-size: 14px;
	text-align: center;

	a { color: #fff; }

	ul {
		padding: 0;
		margin-left: 5px;
		li {
			display: inline-block;
			&:before {
			    content: " • "; /* on affiche une chaîne de caractère */
			    color: @color1;
			    font-size: 28px;
			    vertical-align: middle;
			}
		}
	}
}

.box-mentions--right,
.box-mentions--left {
	display: inline-block;
}

/* mentions légales (/z-templates/affichage/nob_sharing_42.tmpl.html) */

.box-sharing {}
.box-sharing--title {
	display: inline-block;
	margin-right: 15px;
}
.box-sharing--list { display: inline-block; }
.box-sharing--item {
	display: inline-block;
	margin-right: 5px;
}
.box-sharing--link {
	position: relative;
	display: inline-block;
	line-height: 30px;
	text-decoration: none;
	color: white;
	background-color: #0083be; /*Linkedin*/
	width: 30px;
	text-align: center;
	line-height: 30px;
	height: 30px;
	border-radius: 100%;

	/*Facebook*/
	&.icon-facebook { background-color: #3b5998; }
	/*Twitter*/
	&.icon-twitter { background-color: #00abf1; }
	/*Google +*/
	&.icon-google { background-color: #dd4e42; }
	/*Pinterest*/
	&.icon-pinterest { background-color: #ca2127; }

	&:hover, &:focus {
		color: #fff;
		text-decoration: none;
		background-color: darken(#0083be, 20%);

		/*Facebook*/
		&.icon-facebook { background-color: darken(#3b5998, 10%); }
		/*Twitter*/
		&.icon-twitter { background-color: darken(#00abf1, 10%); }
		/*Google +*/
		&.icon-google { background-color: darken(#dd4e42, 10%); }
		/*Pinterest*/
		&.icon-pinterest { background-color: darken(#ca2127, 10%); }
	}
}

/* mentions légales (/z-templates/affichage/switch-lang_46.tmpl.html) */
.box-lang {
	.linkToGTItalie {
		position: relative;
		div#google_translate_element {
			display: none;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}
.box-lang {
	// 490px
	@media (max-width: @screen-phone) {
		background: rgba(255,255,255,0.5);
		padding: 5px;
	}
}
.box-lang--list {
	display: flex;
	>div:last-of-type {
		margin-right: 15px;
	}
}
.box-lang--item {
	align-self: center;
	a {
		margin: auto 4px;
	}
}


/** RESPONSIVE **/




//490px
@media (min-width:@screen-phone) {
	.box-coordinates {
		padding: 30px 35px 55px 35px ;
	}

}

//768px
@media (min-width:@screen-tablet) {

}
//992px
@media (min-width:@screen-desktop) {

}

//1200px
@media (min-width:@screen-lg-desktop) {
}


.box-links_header{
	padding: 10px;
}

.box-links--list{
	font-size: 1.6rem;
	margin-bottom: 0;
	display: flex;
	align-items:center;
}
.box-links--item{
	padding: 0 8px;
	a {
		text-transform: uppercase;
		position: relative;
		padding: 0 0 0 18px;
		&::before {
			content: "";
			width: 3px;
			height: 100%;
			background-color: #000;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
		}
	}
	&:last-child {
		a {
			padding: 0 18px 0 18px;
			margin: 0 10px 0 0;
			&::after {
				content: "";
				width: 3px;
				height: 100%;
				background-color: #000;
				position: absolute;
				top: 0;
				right: 0;
				border-radius: 5px;
			}
		}
	}
}
.box-links--link{
	span{
		color:@text-color;
	}
}
.nav-link-logo{
	text-align: right;
	margin-bottom: 20px;
}
.box-highlight--img{
	img{
		max-width: 125px;
	}
}
