/*  --------------------------------------------------
  --------------------------------------------------
   NAVS : http://getbootstrap.com/components/#nav
--------------------------------------------------
--------------------------------------------------*/

/************ VARIABLES ************/

//=== Shared nav styles
@nav-link-padding:                          10px 9px;
@nav-link-hover-bg:                         @color3;

@nav-disabled-link-color:                   @gray-light;
@nav-disabled-link-hover-color:             @gray-light;

//== Tabs
@nav-tabs-border-color:                     @brand-primary;
@nav-tabs-link-hover-border-color:          @brand-primary;

@nav-tabs-active-link-hover-bg:             transparent;
@nav-tabs-active-link-hover-color:          @text-color;
@nav-tabs-active-link-hover-border-color:   @brand-primary;

@nav-tabs-justified-link-border-color:            @brand-primary;
@nav-tabs-justified-active-link-border-color:     @brand-primary;

//== Pills
@nav-pills-border-radius:                   @border-radius-base;
@nav-pills-active-link-hover-bg:            @component-active-bg;
@nav-pills-active-link-hover-color:         @component-active-color;





/************ STYLES ************/

.nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);

  > li {
    position: relative;
    display: block;

     &.active,&:hover,&:focus{
        & > a{
            &:after, &:before{
                -webkit-transform: scale3d(1, 1, 1)!important;
                transform: scale3d(1, 1, 1)!important;
            }

            &:before{
                -webkit-transition-delay: 0s;
                transition-delay: 0s;
            }
        }
    }


    > a {
      position: relative; 
      display: block;
      padding: @nav-link-padding;
      color:@text-color;
      
      border-bottom: none;

      &:hover,
      &:focus {
        text-decoration: none;
        
      }
    }
    /*debut trait rouge menu*/
    &:not(.espace_client) > a {

            position: relative;
            -webkit-transition: color 0.4s;
            transition: color 0.4s;

            /* &:before{
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 90%;
                height: 3px;
                -webkit-transform: scale3d(0, 1, 1);
                transform: scale3d(0, 1, 1);
                -webkit-transform-origin: center left;
                transform-origin: center left;
                -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
                transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
            }

            &:before{
                background: @color1;
                -webkit-transition-delay: 0.4s;
                transition-delay: 0.4s;
            }*/

            &:after{
                // background: @color1;
            }

        @media (min-width: @screen-tablet) {

            &:hover,&:focus{
                &:before{
                    -webkit-transform: scale3d(1, 1, 1);
                    transform: scale3d(1, 1, 1);
                    -webkit-transition-delay: 0s;
                    transition-delay: 0s;
                }
            }
        }
        /*fin trait rouge menu*/
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: @nav-disabled-link-color;

      &:hover,
      &:focus {
        color: @nav-disabled-link-hover-color;
        text-decoration: none;
        background-color: transparent;
        cursor: @cursor-disabled;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      color: @nav-link-hover-bg;
      border-color: @link-color;
    }
  }

  // Nav dividers (deprecated with v3.0.1)
  //
  // This should have been removed in v3 with the dropping of `.nav-list`, but
  // we missed it. We don't currently support this anywhere, but in the interest
  // of maintaining backward compatibility in case you use it, it's deprecated.
  .nav-divider {
    .nav-divider();
  }

  // Prevent IE8 from misplacing imgs
  //
  // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
  > li > a > img {
    max-width: none;
  }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  //border-bottom: 1px solid @nav-tabs-border-color;
  margin-bottom: -2px;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: -2px;

    // Actual tabs (as links)
    > a {
      margin-right: 2px;
      line-height: @line-height-base;
      border: 2px solid transparent;
      border-radius: @border-radius-base @border-radius-base 0 0;
      background-color: @nav-link-hover-bg;
      &:hover {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
      }
    }

    &.active{
      &:after{
        content: "";
        position: absolute;
        display: inline-block;
        width: 90%;
        margin: 0 auto;
        left: 5%;
        height: 1px;
        background: @nav-tabs-active-link-hover-border-color;
      }
    }
    // Active state, and its :hover to override normal :hover
    &.active > a {
      
      &,
      &:hover,
      &:focus {
        color: @nav-tabs-active-link-hover-color;
        background-color: @nav-tabs-active-link-hover-bg;
        border: 2px solid @nav-tabs-active-link-hover-border-color;
        border-bottom-color: transparent;
        cursor: default;
      }
    }


  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
    .nav-tabs-justified();
  }
}


// Pills
// -------------------------
.nav-pills {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }
}


// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}


// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;

  @media (max-width: @screen-ms-max) {
    display: flex;
  }

  > li {
     float: none; 
    vertical-align: bottom;
    > a {
      text-align: left;
      font-weight: 700;
      padding: 15px;
      border-radius: 0 !important;
      font-size: 16px;
      /* @screen-md */
      @media (min-width: @screen-desktop) {
        font-size: 18px;
      }
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: @screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
  border-bottom: 0;

  > li > a {
    // Override margin from .nav-tabs
    margin-right: 0;
    border-radius: @border-radius-base;
     border-bottom: none;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    border: 2px solid @nav-tabs-justified-link-border-color;
    border-bottom: none;
  }

  @media (min-width: @screen-sm-min) {
    > li > a {
      /* border-bottom: 1px solid @nav-tabs-justified-link-border-color; */
      border-radius: @border-radius-base @border-radius-base 0 0;
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-bottom-color: @nav-tabs-justified-active-link-border-color;
    }
  }
}


// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  border:2px solid @brand-primary;
  padding: 25px 15px;
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}


// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  .border-top-radius(0);
}
