/**
*
* Eléments réutilisables :
* boutons, icônes, popin (lightbox, notification…), slider (styles communs)…
*
**/
/*=============================
=            Titre            =
==============================*/

h1, .h1 {
	text-align: center;
	position: relative;
	margin-bottom: 35px;

	&:after {
		content : "";
		border-bottom: 2px solid @color1;
		width: 38px;
		position: absolute;
		bottom: -12px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
	}
}

article > h1,
.home .container > h1,
article > .h1,
.home .container > .h1 {
	margin-top: 55px;
}

/*===================================
=            Alert Oldie            =
===================================*/

.oldie {
	.alert-ie {
		border-top:10px solid #e44204;
		& > div {
			padding: 20px 70px;
			background: url(/images/alert-ie.png) left center no-repeat;
		}
		strong { color:#e44204; }
	}
}

/*=====  End of Alert Oldie  ======*/


/*==================================
=            Skip links            =
==================================*/

.skip-nav {
	position: absolute;
	padding: 0;
	list-style: none;
	z-index:9999;
	a {
		position: absolute;
		left: -7000px;
		padding: 0.5em;
		background: @gray-base;
		color: white;
		text-decoration: none;
		&:focus{
			position: static;
		}
	}
}

/*=====  End of Skip links  ======*/


/*==========================================
=            Cookies validation            =
==========================================*/

.alert-cookie {
	transition:none;
	overflow: hidden;
	text-align: center;
	background-color: @text-color;
	background-color: rgba(0,0,0,0.85);
	color:#fff;
	animation: cookie-anim 1s ease-in;
	padding: 10px;
	margin: 0;

	&.is-hidden{
		animation: none;
		max-height: 0;
		padding:0;
		margin: 0;
		transition: all 0.5s ease-out;
	}

	&.fixed-bottom{
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 0;
		z-index: 9999;
	}
}
@keyframes cookie-anim {
	0% { max-height: 0; padding:0; margin:0;}
	100% { max-height: 70px; padding: 10px; }
}
.alert-cookie--link, .alert-cookie--link:visited {
	display: inline-block;
	color: #fff;
	&:hover, &:focus{
		color: #fff;
	}
}
.alert-cookie--btn {
	/* Retrouver les class .btn et .btn-default directement dans le fichier /zephyr/cookie.js */
}

/*=====  End of Cookies validation  ======*/

/*=====================================
=            Liste à puces            =
=======================================*/

li {
	list-style-type: none;
	line-height: 28px;
}

article :not(footer) li:before {
    content: "• "; /* on affiche une chaîne de caractère */
    color: @color1;
    font-size: 28px;
    vertical-align: middle;
}

/*=====  End of Liste à puces  ======*/

/*=============================
=            Liens            =
===============================*/

article a {
    text-decoration: underline;
    color : @color2;
}

/*=====  End of Liens  ======*/

/*=============================
=            Liens            =
===============================*/

.baseline {
    font-size: 28px;
    font-style: italic;
    font-weight: 600;
    color: @color4;
    position: relative;
    text-align: center;

    &:after {
    	content : "";
		border-bottom: 2px solid @color4;
		width: 64px;
		position: absolute;
		bottom: -7px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
    }
}

.home .baseline {
	font-size: 38px;
}

/*=====  End of Liens  ======*/

/*=============================
=            Title            =
===============================*/

.title  {
    color : #fff;
    text-align: center;
    font-size: 30px;
    position: relative;

    &:after {
    	content : "";
		border-bottom: 2px solid @color3;
		width: 38px;
		position: absolute;
		bottom: -5px;
		left: 0;
		right: 0;
		margin: auto;
		text-align: center;
    }
}

/*=====  End of Title  ======*/

/*==============================
=            Boutons           =
================================*/

.btn-next {
    color: @color2;

    &:after {
    	content: "\e608";
    	.icomoon;
    	vertical-align: middle;
    	margin-left: 5px;
    	position: absolute;
    }
    &:hover, &:focus {
    	text-decoration: none;
    	color: @color1;

    	&:after {
    		.arrow-infinite;
    	}
    }

    a {
    	&:hover, &:focus {
	    	text-decoration: none;
	    	color: @color1;
	    }
    }
}

/*.btn-default, .submit {
	position: relative;
	&:before {
		content: "\e608";
		.icomoon;
	}
}*/

/*=====  End of Boutons  ======*/
/*===========================
=            Pipe           =
=============================*/

.pipe {
    color: @color3;
    -ms-transform: rotate(15deg); /* IE 9 */
    -webkit-transform: rotate(15deg); /* Chrome, Safari, Opera */
    transform: rotate(15deg);
    position: absolute;
    font-size: 30px;
    font-family: Initial;
    margin-top: -7px;
    margin-left: -5px;
}

/*=====  End of Pipe  ======*/

/*================================================
=            images dans les articles            =
================================================*/
.intext_left,
.intext_right {
	//768px
	@media (min-width:@screen-tablet) {
		max-width: 40%;

		img {
			width: 100%;
			margin: 5px 0,;
		}
	}
}

.intext_right {
	//768px
	@media (min-width:@screen-tablet) {

		img {
			float: right;
		}
	}
}
.intext_left{
	//768px
	@media (min-width:@screen-tablet) {

		img {
			float: left;
		}
	}
}

.textAndImages img { max-width: 100%; }
/*=====  End of images dans les articles  ======*/

/*============================
=            images          =
=============================*/

img {
	max-width: 100%;
}


/*=====  End of images  ======*/

/*=====================================
=            bloc de couleur          =
======================================*/

.bg-color1 {
	background-color: @color1;
}
.bg-color2 {
	background-color: @color2;
}
.bg-color3 {
	background-color: @color3;
}
.bg-color4 {
	background-color: @color4;
}
.bg-color5 {
	background-color: @color5;
}
.bg-color6 {
	background-color: @color6;
}

.bloc-home:not(.bloc9):not(.bloc1):not(.bloc10) > div {
	height: 165px;
	color: white;
	text-align: center;
	text-transform: uppercase;
	font-size: 14px;
	overflow-x: hidden;
	p:last-child {
		margin: 0;
	}

	span {
		font-size: 35px;
		margin-top: 22px;
		display: inline-block;
	}
	img {
		//7490x
		@media (min-width:@screen-phone) {
			max-width: none;
			height: 100%;
		}

	}
}

.bloc1, .bloc2, .bloc3 {
	margin-bottom: 30px;
}
.bloc1-container {
	position: relative;
	.subtitle-bloc {
		text-transform: uppercase;
		color: white;
		position: absolute;
		bottom: 15px;
	    left: 25px;
	    font-size: 20px;
	    //490px
		@media (max-width:@screen-phone) {
			background: rgba(0,0,0,.5);
	    	padding: 4px 9px 0 9px;
	    	margin-right: 20px;
		}

	}
	img {
		width: 100%;
	}
}

.bloc1 > div { }
.bloc3 > div { }
.bloc4 {
	margin-bottom: 30px;
	> div { }
}
.bloc5 {
	margin-bottom: 30px;
	> div { }
}
.bloc6 {
	margin-bottom: 30px;
	> div { }
}
.bloc7 > div { }
.bloc8 > div {
	span {
		font-size: 75px!important;
		margin-bottom: 13px;
	}
}
.bloc9 {
	margin-top: 30px;
	.bloc9-container {
		background-image: url(/images/refonte/nos_actions.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		color: @text-color;
		text-transform: none;
		text-align: left;
		padding: 15px 10px 15px 10px;
		line-height: 18px;
		//490px
		@media (min-width:@screen-phone) {
			line-height: 16px;
		}
	}

	.title-bloc {
		color: @color4;
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 500;
		position: relative;
		margin-bottom: 10px;
		//490px
		@media (min-width:@screen-phone) {
			font-size: 20px;
		}
		&:after {
			content: "";
			border-bottom: 3px solid @color4;
			width: 38px;
			position: absolute;
			left: 0;
			bottom: 2px;
			margin: auto;
		}
	}
}

.js-bloc9--link-all {
	&:hover, &:focus {
		text-decoration: none;
	}
}

.bloc10 {
	.title-bloc {
		color: @color4;
		font-size: 24px;
		text-transform: uppercase;
		font-weight: 500;
		position: relative;
		margin-bottom: 10px;
		//490px
		@media (min-width:@screen-phone) {
			font-size: 20px;
		}
		&:after {
			content: "";
			border-bottom: 3px solid @color4;
			width: 38px;
			position: absolute;
			left: 0;
			bottom: 2px;
			margin: auto;
		}
	}

	article {
		width: 100%;
	}
	.home-all-actus,
	.news--item-infos {
		display: none;
	}
}

.title-bloc {
	font-size: 50px;
	margin: 0;
	line-height: 50px;
	font-weight: 300;
}


/*=====  End of bloc de couleur  ======*/

/*=================================
=            flex items          =
==================================*/

.flex-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
}

.flex-cell {
  // flex: 0 0 20%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
}

.flex-cell:before {
  content: '';
  display: table;
  padding-top: 100%;
}

.flex-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*=====  End of flex items  ======*/
/*====================================
=            Effets hover            =
====================================*/

.arrow-infinite {
	animation-duration: 0.5s;
	animation-name: movearrow;
	animation-iteration-count: infinite;
	animation-direction: alternate;
}

@keyframes movearrow {
  from { margin-left: 0px; }
  to { margin-left: 10px; }
}


/*Menu effet hover*/


.main_menu > ul > li,
.dropdown {
  // float: left;
  display: inline-block;

  position: relative;
  perspective: 600px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  -o-perspective: 600px;
  -ms-perspective: 600px;
  perspective-origin: 50% 50%;
  -webkit-perspective-origin: 50% 50%;
  -moz-perspective-origin: 50% 50%;
  -o-perspective-origin: 50% 50%;
  -ms-perspective-origin: 50% 50%;
}




.main_menu > ul > li > ul.sub-menu,
.dropdown > .dropdown-menu , .dropdown > .dropdown-menu > .dropdown-menu {

  transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -90deg) scale3d(1, 1, 1);
  -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -90deg) scale3d(1, 1, 1);
  -moz-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -90deg) scale3d(1, 1, 1);
  -o-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -90deg) scale3d(1, 1, 1);
  -ms-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, -90deg) scale3d(1, 1, 1);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  transform-origin: 0% 0% 0px;
  -webkit-transform-origin: 0% 0% 0px;
  -moz-transform-origin: 0% 0% 0px;
  -o-transform-origin: 0% 0% 0px;
  -ms-transform-origin: 0% 0% 0px;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  -webkit-transition: 0.4s ease 0s all;
  -o-transition: 0.4s ease 0s all;
  -moz-transition: 0.4s ease 0s all;
  -ms-transition: 0.4s ease 0s all;
  transition: 0.4s ease 0s all;
}
.main_menu > ul > li:hover > ul.sub-menu,
.dropdown:hover > .dropdown-menu, .dropdown:hover > .dropdown-menu:hover > .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg) scale3d(1, 1, 1);
  -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg) scale3d(1, 1, 1);
  -moz-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg) scale3d(1, 1, 1);
  -o-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg) scale3d(1, 1, 1);
  -ms-transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg) scale3d(1, 1, 1);
}


/*=====  End of Effets hover  ======*/


.home-first-container{
    padding-top: 65px;
    position: relative;

    @media (min-width: @screen-tablet) {
        padding-top: 120px;

      /*   &:before{
          content:"\e90d";
          font-family: 'icomoon';
          color:$gray-400;
          position: absolute;
          top: 380px;
          left: -405px;
          font-size: 695px;

      } */
    }

    @media (min-width: @screen-desktop){
        padding-top: 135px;
    }
    @media (min-width: @screen-lg-desktop) {
        padding-top: 180px;
    }


}

.home-baseline--txt{
    color:@white;
    font-weight:700;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    text-align: center;
    order:1;



    /* @screen-md */
     @media (min-width: @screen-desktop) {
     	margin-bottom: 20px;
		font-size: 30px;

         &:before{
            width: 100px;
            height: 50px;
         }
    }

    @media (min-width: @screen-lg-desktop) {
        font-size: 4.5rem; /*45*/
        /* padding-left: 215px; */


        &:before{
            width: 200px;
            height: 100px;
        }
    }
    span{
        display: block;
        font-weight: 400;
    }
}

.element-reversed{
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.home-section{
    margin-top: 55px;


    /* @screen-md */
     @media (min-width: @screen-desktop) {


        margin-top: 90px;
    }

   @media (min-width: @screen-lg-desktop) {
        margin-top: 120px;
    }
}


/*FAQ*/

.panel-default>.panel-heading {
	&:hover, &:focus {
		background-color : #ddd;
	}

	a {
		font-weight: bold;
		font-size: 19px;
	}

	a.collapsed {
			.icon-close {
				transform: rotate(45deg); /* Équivalent à rotateZ(45deg) */
			}
	}

	
}

.cover {
	overflow: hidden;
	img {
	    object-fit: cover;
	    font-family: "object-fit: cover;";
	    height: 100%;
	    width: 100%;
	}
}