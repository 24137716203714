/*  --------------------------------------------------
  --------------------------------------------------
   JUMBOTRON : http://getbootstrap.com/components/#jumbotron
--------------------------------------------------
--------------------------------------------------*/

/************ VARIABLES ************/
@jumbotron-padding:              30px;
@jumbotron-color:                inherit;
@jumbotron-bg:                   @gray-lighter;
@jumbotron-heading-color:        inherit;
@jumbotron-font-size:            ceil((@font-size-base * 1.5));



/************ STYLES ************/

.jumbotron {
  padding: @jumbotron-padding (@jumbotron-padding / 2);
  margin-bottom: @jumbotron-padding;
  color: @jumbotron-color;
  background-color: @jumbotron-bg;

  h1,
  .h1 {
    color: @jumbotron-heading-color;
  }

  p {
    margin-bottom: (@jumbotron-padding / 2);
    font-size: @jumbotron-font-size;
    font-weight: 200;
  }

  > hr {
    border-top-color: darken(@jumbotron-bg, 10%);
  }

  .container &,
  .container-fluid & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: @screen-sm-min) {
    padding: (@jumbotron-padding * 1.6) 0;

    .container &,
    .container-fluid & {
      padding-left:  (@jumbotron-padding * 2);
      padding-right: (@jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: (@font-size-base * 4.5);
    }
  }
}
