/*  --------------------------------------------------
  --------------------------------------------------
   Badges : http://getbootstrap.com/components/#badges
--------------------------------------------------
--------------------------------------------------*/

/************ VARIABLES ************/

@breadcrumb-padding-vertical:   4px;
@breadcrumb-padding-horizontal: 10px;
//** Breadcrumb background color
@breadcrumb-bg:                 rgba(255,255,255,.65);
//** Breadcrumb text color
@breadcrumb-color:              @gray-dark;
//** Text color of current page in the breadcrumb
@breadcrumb-active-color:       @color3;
//** Textual separator for between breadcrumb elements
@breadcrumb-separator:          "•";


/************ STYLE ************/

.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;

  
  > li {
    display: inline-block;

    + li:before {
      content: "\00a0@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-active-color;
      font-size: 28px;
      vertical-align: middle;
    }
  }
  a {
    color: @breadcrumb-color;
    font-size: 15px;
  }

  >:last-child a {
    color: @breadcrumb-active-color;
  }
}
