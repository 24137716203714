/*	--------------------------------------------------
	--------------------------------------------------
		Gestion des fonts.
		Possibilité de créer une mixin par font

	--------------------------------------------------
	--------------------------------------------------*/


@font-path:"fonts/";

/** ICOMOON **/
@font-icon-folder:"icomoon/";
@font-icon-name:"icomoon";
@font-icon-svg-id:"icomoon";


@font-face {
	font-family: @font-icon-name;
	src:url('@{font-path}@{font-icon-folder}@{font-icon-name}.eot');
	src:url('@{font-path}@{font-icon-folder}@{font-icon-name}.eot') format('embedded-opentype'),
		url('@{font-path}@{font-icon-folder}@{font-icon-name}.ttf') format('truetype'),
		url('@{font-path}@{font-icon-folder}@{font-icon-name}.woff') format('woff'),
		url('@{font-path}@{font-icon-folder}@{font-icon-name}.svg#@{font-icon-svg-id}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icomoon {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-camion2:before {
  content: "\e91e";
}
.icon-chrono:before {
  content: "\e91f";
}
.icon-globe:before {
  content: "\e920";
}
.icon-palettes:before {
  content: "\e921";
}
.icon-traabilit:before {
  content: "\e922";
}
.icon-chevron-double-small-right:before {
  content: "\e60d";
}
.icon-chevron-double-small-left:before {
  content: "\e60e";
}
.icon-chevron-double-thin-left:before {
  content: "\e60f";
}
.icon-chevron-double-thin-right:before {
  content: "\e610";
}
.icon-google:before {
  content: "\e600";
}
.icon-add:before {
  content: "\e905";
}
.icon-arrow:before {
  content: "\e906";
}
.icon-calendar2:before {
  content: "\e907";
}
.icon-delivery-truck:before {
  content: "\e908";
}
.icon-earth-with-a-circle-of-three-arrows:before {
  content: "\e909";
}
.icon-edit:before {
  content: "\e90a";
}
.icon-internet:before {
  content: "\e90b";
}
.icon-menu-1:before {
  content: "\e90c";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-paper-plane:before {
  content: "\e90e";
}
.icon-picto-ca:before {
  content: "\e90f";
}
.icon-picto-camion:before {
  content: "\e910";
  color: #fff;
}
.icon-picto-departs:before {
  content: "\e911";
}
.icon-picto-engagements:before {
  content: "\e912";
  color: #fff;
}
.icon-picto-formulaire:before {
  content: "\e913";
}
.icon-picto-palettes:before {
  content: "\e914";
}
.icon-picto-tour-terre:before {
  content: "\e915";
  color: #fff;
}
.icon-placeholder:before {
  content: "\e916";
}
.icon-play-button:before {
  content: "\e917";
}
.icon-search:before {
  content: "\e918";
}
.icon-share2:before {
  content: "\e919";
}
.icon-smartphone:before {
  content: "\e91a";
}
.icon-success:before {
  content: "\e91b";
}
.icon-users:before {
  content: "\e91c";
}
.icon-close:before {
  content: "\e209";
}
.icon-done:before {
  content: "\e28a";
}
.icon-mail:before {
  content: "\e601";
}
.icon-linkedin:before {
  content: "\e91d";
}
.icon-chevron-small-left:before {
  content: "\e607";
}
.icon-chevron-small-right:before {
  content: "\e608";
}
.icon-chevron-thin-left:before {
  content: "\e609";
}
.icon-chevron-thin-right:before {
  content: "\e60a";
}
.icon-location-pin:before {
  content: "\e60b";
}
.icon-twitter:before {
  content: "\e60c";
}
.icon-share:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-arrow-right:before {
  content: "\e602";
}
.icon-arrow-left:before {
  content: "\e603";
}
.icon-user:before {
  content: "\e604";
}
.icon-printer:before {
  content: "\e605";
}
.icon-calendar:before {
  content: "\e606";
}
.icon-tripadvisor:before {
  content: "\e900";
}
.icon-pinterest-p:before {
  content: "\e901";
}
.icon-youtube-play:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\f095";
}
.icon-facebook:before {
  content: "\f09a";
}
