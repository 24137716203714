/**
*
* Composants spécifique au CMS e-majine
*
**/


/*=========================================
=            Article en bloc            =
=========================================*/

.bloc-simple, .bloc-double {
	margin-top: 0;
	margin-bottom: 0;
}
.medias, .map {
	margin-bottom: 15px;
}
.bloc-double + .bloc-double ul, .intext_left + div ul {
	list-style-position: inside;
}
.intext_left, .intext_right {
	float: none;
	margin-bottom: 10px;
}
.above_left, .above_right, .above_center {
	margin-bottom: 10px;
}
.below_left, .below_right, .below_center {
	margin-bottom: 10px;
}
.above_left, .below_left {
	text-align: left;
}
.above_right, .below_right {
	text-align: right;
}
.above_center, .below_center {
	text-align: center;
}

@media (min-width:@screen-tablet) {
	.intext_left {
		float: left;
		margin-right: 15px;
	}
	.intext_right {
		float: right;
		margin-left: 15px;
	}
}

/*=====  End of Article en bloc  ======*/

/*==================================
=            Re-captcha            =
==================================*/

#recaptcha_image, .recaptchatable .recaptcha_image_cell center img, .recaptchatable .recaptcha_image_cell center, .recaptchatable .recaptcha_image_cell, .recaptchatable #recaptcha_response_field {
	height: auto !important;
	width: 100% !important;
}
#captcha .recaptchatable {
	border: none !important;
}
.recaptcha {
	max-width: 400px;
}
#recaptcha_response_field {
	border: 1px solid #cccccc !important;
	padding: 2px 5px !important;
	color: #000000 !important;
}
#recaptcha_table td + td + td {
	padding: 0 !important;
}
.recaptcha a img {
	max-width: none;
}
#recaptcha_logo, #recaptcha_privacy {
	display: none;
}

/*=====  End of Re-captcha  ======*/

/*=======================================
=            Alerte e-majine            =
=======================================*/

/* etoile pour champs obligatoire */
.obligatory{
	color: @brand-danger;
}
/* Message "Champ obligatoire" */
.errorForm, .error-form{
	.label;
	.label-danger;
}

/* Reste à tester */
.form-group.error {
	.has-error;
	.has-feedback;
}
.correct {
	.alert;
	.alert-success;
}
.confirmation_validation {
	
}

/*=====  End of Alerte e-majine  ======*/

/*=============================================
=            check password           =
=============================================*/

.strength_password, .confirmpasswd {
	padding-left: 10px;
}
.shortPass, .badPass, .notequalpasswd {
	color: @brand-danger;
}
.goodPass {
	color: @brand-warning;
}
.strongPass, .equalpasswd {
	color: @brand-success;
}

/*=====  End of check password  ======*/






