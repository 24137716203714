/**
*
* Formulaires
*
**/

/*========================================
=            Form par default            =
========================================*/

/*----------  Header / Footer  ----------*/

.form--header, .form--footer {
	margin-top: 20px;
}

form .submit {
	.text-center;
}

.form-group {
    margin-bottom: 30px;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	  color: @text-color;
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: @text-color;
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: @text-color;
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: @text-color;
}
}

/*----------  Checkbox  ----------*/
.checkbox {}
.checkbox--value {}

/*----------  Checkbox  ----------*/
.radio {}
.radio--value {}

/*----------  Checkbox & Radio other  ----------*/
.checkbox--other, .radio--other {
	label {
		width: auto;
	}
	input[type="checkbox"] {
		margin-top: 8px;
	}
}
	.checkbox--other-input, .radio--other-input {
		width: auto;
    	display: inline;
	}

/*----------  Radios visual  ----------*/
.radio--visual {}
.radio--visual-item {}
.radio--visual-img{}

/*----------  commons  ----------*/
.calendar-link, .calendar-link:visited {
	width:20px;
	height: 20px;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMFJREFUeNpiZEADxsbGCUBKAYgfnD17dgEDDoBLHRMWtfFAXA+l8QGs6lgYqACArjUAUgIgNiOQ0wC1CQQOQGkHNDY6wKkO3csgwQtA3AilHXA4Cqc6bF4uINKnWNVRGoYH0PgGMAMPAKPekQqRs5+JgcqABZstUOYFoKsLoWL9IO+giWGowxWG2GLWAIu4A7GRAgvoC0hiF7CIYVMHdnoDkvOHRqT8R09KUB84oIn9x5bkaO/CkRMpDkiBTBEACDAA8QlQziDYGWsAAAAASUVORK5CYII=);
	background-position:center center;
	background-repeat:no-repeat;
	background-size: contain;
}
.no-svg .calendar-link { background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAGFBMVEUAAAAzMzMzMzMzMzMzMzMzMzMzMzMzMzOW6p+xAAAAB3RSTlMAv4CPYDrv5++3twAAAE9JREFUeAFjYGBwcoARDOICYALBFA1lUC8UFywvBxEM6uVG5uXlQALEhAI4s1BQjEGRAQoYUZmCBszCQALELBdgLAQSaKKY2pDUkqYN4TIA4vUcGha03vYAAAAASUVORK5CYII=); }


/*=====  End of Form par default  ======*/

.formulaire h2 { font-size: 24px; }