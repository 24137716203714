/* *
*
* Navigation
* Menu, breadcrumb, pagination, pager, ...
*
*

gestion du tactile quand le menu repasse en desktop
*/
@media (min-width:@grid-float-breakpoint) {
  ul.nav li:hover > ul.dropdown-menu,
  ul.nav li:focus > ul.dropdown-menu, .dropdown.open > ul.dropdown-menu {
	display: block;
  }

  .no-touch ul.nav li:hover > ul.dropdown-menu {
	display: none;
  }

  .no-touch ul.nav li.open > ul.dropdown-menu {
	display: block;
  }

}

.dropdown{
	> a{
		.caret{
			display: none;
		}
	}

	
}

.dropdown-toggle{
	.caret{
		float: right;
	}
	.caret:after{
		display: block;
	    content: "\e60a";
	    font-family: 'icomoon';
	    speak: none;
	    font-style: normal;
	    font-weight: normal;
	    font-variant: normal;
	    text-transform: none;
	    line-height: 1;
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;
	    
	    margin-top: 5px;
	    margin-right: -10px;
	    color: #ef4230;
	    font-size: 12px;
}
	
	
}
/*

=======================================
=            Animation Menu             =
=======================================

=====  End of Animation Menu ======



.navbar-default {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: @navbar-default-bg;
  z-index: 1;
  Affix taille menu
  @media (min-width: @screen-tablet) {
	max-width: 768px;
	margin: auto;
  }
   @media (min-width: @screen-desktop) {
	max-width: 992px;
  }
   @media (min-width: @screen-lg-desktop) {
	max-width: 1170px;
  }


}
.navbar-nav>li a{
  @media (max-width: @screen-sm-max) {
	font-size: 14px !important;
  }
}
.navbar-nav { 

 @media (min-width: @screen-tablet) {
   display: table;
   width: 100%;
 }
  > li {   
	text-align: center;

	&.dropdown > a{
		span {
		  padding-right: 5px;
		}

		&:after {
		  display: block;
		  content: "\e60a";
		  font-family: 'icomoon';
		  .icomoon;
		  float: right;
		  margin-top: 2px;
		  margin-right: 0px;
		  color: #FFFFFF;
		  font-size: 16px;
		  -ms-transform: rotate(90deg); IE 9
		  -webkit-transform: rotate(90deg); Chrome, Safari, Opera
		  transform: rotate(90deg);
		}
	}

	&:last-child {
	//    > a {        
	// 	span {
	// 	  padding-right: 5px;
	// 	}

	// 	&:after {
	// 	  display: block;
	// 	  content: "\e60a";
	// 	  font-family: 'icomoon';
	// 	  .icomoon;
	// 	  float: right;
	// 	  margin-top: 2px;
	// 	  margin-right: 0px;
	// 	  color: #FFFFFF;
	// 	  font-size: 16px;
	// 	  -ms-transform: rotate(90deg); IE 9
	// 	  -webkit-transform: rotate(90deg); Chrome, Safari, Opera
	// 	  transform: rotate(90deg);
	// 	}

	//   } 
	  a {
		background-color: @color1!important;
		&:hover, &:focus {
		  background-color: @color1!important;
		  color: white;
		}
	  }
	  // on donne une taille max au submenu
	  .dropdown-menu {
		min-width: 179px;
	  }
	}

	@screen-md
	@media (min-width: @screen-tablet) {
	   display: table-cell;
	}

	> a {
	  padding: 17px 14px;
	  color: @navbar-default-color;
	  text-transform: uppercase;

	  @media (max-width: @screen-sm-max) {
		font-size: 14px !important;
	  }


	}

  

  &.dropdown {
	&:nth-last-child(3){
	  @media (min-width: @screen-tablet) {
		.dropdown-menu{
		  left: auto;
		  right: 0;

		  .dropdown-menu{
			right: auto;
			left:100%;
		  }
		}
	  }
	}
	 

	&.open,
	&:hover,
	&:focus {
	  > a{
		color: white;
		background: @color4darker;
		// color:@brand-danger;
		// background-color: @navbar-default-link-hover-bg;
	  }
		
	  & > .dropdown-menu {
	   -webkit-transform: scale(1, 1);
	   transform: scale(1, 1);

	   overflow: visible;
	   visibility:visible;
	   opacity: 1;
	   height: auto;
	  } 

	  &:after {
		-moz-transform: rotate(-90deg) translate(-15px, 0);
		-ms-transform: rotate(-90deg) translate(-15px, 0); IE 9
		-webkit-transform: rotate(-90deg) translate(-15px, 0); Chrome, Safari, Opera
		transform: rotate(-90deg) translate(-15px, 0);
		color: @color4darker;
	  }

	}
	// @media (max-width: @grid-float-breakpoint) {
	//   &:hover,
	//   &:focus {
	//     & > .dropdown-menu {
	//      overflow: hidden;
	//      opacity: 0;
	//      visibility: hidden;
	//      height: 0;
	//     } 
	//   }
	// }
  }
	a{
	  font-weight: 500;
	  font-size: 13px;

	  @media (min-width: @screen-lg-desktop){
		font-size: 18px;
	  }
	}
  }

  .dropdown-menu {
	height: 0;
	visibility: hidden;
	overflow: hidden;
	opacity: 0;  
	margin-top: 0; 
  }

  .dropdown-submenu{
	&.open,
	  &:hover,
	  &:focus {
		> a{
		  color:@brand-danger;
		  background: @color4;
		}
		& > .dropdown-menu {
		  overflow: visible;
		 visibility:visible;
		 opacity: 1;
		 height: auto;
		}
	  }

	  .dropdown-menu {
		@media (max-width: @screen-tablet){
		  font-style: italic;
		  // margin-left: 20px;
		}
	  }
  }
}


.navbar-default .navbar-collapse, .navbar-default .navbar-form{
	margin: 0;
	padding: 0;
}

.dropdown{
	&.open, &:hover, &:focus{
		& > .dropdown-menu {
			@media (min-width: @screen-desktop) {
				 -webkit-transform: scale(1, 1);
				transform: scale(1, 1);  
			}
		}
	}
	
}

.dropdown-menu {
  
  @media (min-width: @screen-desktop) {
	  transform-origin: top;
	  animation-fill-mode: forwards; 
	  transform: scale(1, 0);
	  display: block;  
	  transition: all 0.2s ease-out;
  }
}

dernier drop down-menu, pas de fleche
.dropdown-menu .dropdown-menu a:after {
  display: none;
}

Nav sur la home
//768px

.home .header .container-header {
  top: auto;
  bottom: 0;
  border-radius: 0;
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	z-index: 3;
	border: none;
	min-height: 0;
	@media (min-width:@screen-tablet) {
		padding-top: 50px;
	}
}

.home .header .navbar:not(.affix) {
  border-radius: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 3;
  border: none;
  min-height: 0;
  max-width: 1170px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
  @media (min-width:@screen-tablet) {
	  //top: 50px;
  }
   // 490px
  @media (max-width: @screen-phone) {
	margin-top: 231px!important;
  }
   // 768px
  @media (max-width: @screen-tablet) {
	margin-top: 531px;
  }

  @media (min-width:@screen-tablet) and (max-width: @screen-desktop) {
	margin-top: 509px;
  }
}
@media (min-width: @screen-desktop){
  // si on scroll et que le menu affix n'est pas lancé
  .home .header .navbar:not(.affix) {
	  top: auto!important;
	  bottom: 0;
  }
  
}
.home .header .navbar.affix-bottom {
  top:0!important;
  bottom: auto;
}

===================MJMJ=====================

*
*
* Eléments de navigation
*
*

==============================
=            Navbar            =
==============================


gestion du tactile quand le menu repasse en desktop
@media (min-width: @grid-float-breakpoint) {
  ul.nav li:hover > ul.dropdown-menu, ul.nav li:focus > ul.dropdown-menu{ display: block;  }
  .no-touch ul.nav li:hover > ul.dropdown-menu{ display: none; }
  .no-touch ul.nav li.open > ul.dropdown-menu { display: block;}
}



@media (max-width: @grid-float-breakpoint-max) {
  
  .navbar-collapse {
	  top: 0;
	  left: 0;
	  background: fade(@color4, 90%);
	  // background: @red-dark;
	  z-index: 2;
	  height: 100%!important;
	  position: fixed;
	  padding-bottom: 40px;
	  width: 80%;
	  min-width: 255px;
	  max-width: 600px;
	  transform: translateX(-480px);
	  transition: all .3s;
	&.in {
	  transform: translateX(0);
	}
  }

  .navbar-nav {
	margin: 20px 10px 0;
	& > li {
	  background-color: @color4darker;
	  margin-bottom: 10px;
	  &:hover { overflow: hidden; }
	}
	& > .dropdown > a{
	  padding-right: 30px;
	  &:after {
		  position: absolute;
		  top: 11px;
		  right: 7px;
		  font-size: 22px;
		  content: "\e608";
		  .icomoon();
		  color: red;
	  }
	}
  }
  

  .navbar-default .navbar-nav .open .dropdown-menu>.dropdown-submenu.open>a {
	background-color: @gray-lighter;
	color: @color4darker;
  }

  .navbar-nav .open .dropdown-menu {
	padding-left: 20px;
  }
}

.navbar-default {
  .navbar-toggle {
	background-color: @color1;

	&:hover, &:focus {
		background-color: @color1;  
	}
	&[aria-expanded="true"] {
	  background-color: @color1;
	  .icon-bar:nth-child(2) {
		transform: rotate(45deg);
		position: relative;
		left: 12px;
		top: 2px;
	  }
	  .icon-bar:last-child{
		transform: rotate(-45deg);
		position: relative;
		left: 12px;
		top: 0px;
	  }
	  .icon-bar:nth-child(3) {
		opacity: 0;
	  }
	}

	@screen-sm
	@media (min-width: @screen-sm) {
	  margin: 15px 0 0;
	}

	@screen-md
	@media (min-width: @screen-md) {
	  margin: 75px 0 0;
	}
  }
}


=====  End of Navbar  ====== */

.navbar{
	padding: 0;
	.logo {
		margin-bottom: 15px;
	}
}

.navbar-nav {

  /* @screen-md */
  @media (min-width: @screen-tablet) {
  	
  
    /* margin-right: 50px; */
  }
  @media (min-width: @screen-desktop){
    /* margin-right: 40px; */
    /* width: 90% !important; */
    margin-left: auto;
  }
  
  @media (min-width: @screen-lg-desktop) {
    /* width: 85% !important; */
    /* margin-right: 70px; */
  }
  > li {
	flex-grow: 1;
    text-align: center;
    > a {
      padding: 15px;
      text-transform: uppercase;
      font-weight: 600;
      position: relative;
      font-size: 20px;
	  padding-bottom: 30px !important;
	  font-weight: 200;
      @media (min-width: @screen-tablet) {
      	padding: 15px 5px;
      }

      /* @screen-md */
      @media (min-width: @screen-lg-desktop) {
      	padding: 10px;
      }

      &:before {
        display: inline-block;
        content: "";
        width: 100%;
        height: 3px;
        background: @white;
        position: absolute;
        bottom: 15px;
        left: 0rem;
        transition: all 0.2s ease;
      }

      &:hover,
      &:focus {
        color: @color4darker;

        &:before {
          background: @color4darker;
        }
      }
    }

    &.show,
    &:hover,
    &.active {
      > a {
        color: @color4darker;

        &:before {
          background: @color4darker;
        }
      }
    }
	.dropdown-menu {
		min-width: 100%;
		>li>a {
			padding: 8px 15px;
			display: flex;
			align-items: center;
			transition: color .3s;
			&:hover {
				background-color: #1e3f5e;
				color: #ef4230;
			}
			span {
				flex-grow: 1;
			}
			&::after {
				content: "";
				width: 26px;
				height: 30px;
				background-image: url(/images/refonte2022/arrow-menu.svg);
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
  }
}

.dropdown-submenu {
  font-weight: 300;

  a {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  &.active{
    > a{
      color:@color4darker;
    }
  }

}

.navbar-brand {
  max-width: 18rem;
  flex-shrink: 0;
  padding: 0;
  margin-right: auto;
  height: auto;
	
  &:focus {
    outline: none;
  }
  @media (min-width: @screen-tablet) {
  	max-width: 14rem;
  }


  @media (min-width: @screen-desktop) {
    margin-right: 0;
  }
  @media (min-width: @screen-lg-desktop) {
     max-width: none;

  }

}

.navbar-toggler-icon {
  width: 30px;
  height: 4px;
  background: @color4darker;
  display: block;
  position: relative;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  border-radius: 3px;

  & + .navbar-toggler-icon {
    margin-top: 0px;
  }

  &:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &:nth-child(2) {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  &:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-top: -9px;
  }

}

.navbar-collapse{
	/* @screen-md */
	@media (min-width: @screen-desktop) {
		padding-right: 0;
	}
	.dropdown-toggle::after{
		display: none;
	}
}

.collapsed {
  .navbar-toggler-icon {
    & + .navbar-toggler-icon {
      margin-top: 5px;
    }

    &:nth-child(1) {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

    &:nth-child(2) {
      opacity: 1;
      filter: alpha(opacity=100);
    }

    &:nth-child(3) {
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }

  }

}



@media (max-width: 991px) {
  .navbar-collapse {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-100%);
    transition: all 0.2s ease;
    background: @white;
    text-align: center;
    padding: 0 15px;
    /* height: 100vh; */


    &.in {
      transform: translateY(78px);
    }

    .dropdown-menu {
      text-align: center;
    }

    .nav-link {
      padding-bottom: 0px;
    }

  }

}

@media (min-width: @screen-tablet) {
  .navbar-collapse {
    &.show {
      transform: translateY(80px);
    }

  }
}

.navbar {
  @media (min-width: @screen-lg-desktop){
    font-size: 1.6rem;
  }

}


.highlight-item, .espace_client{
	> a{
		color:@color1 !important;
	}
}