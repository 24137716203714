/*  --------------------------------------------------
  --------------------------------------------------
   PAGINATION : http://getbootstrap.com/components/#pagination-default

   Multipages
--------------------------------------------------
--------------------------------------------------*/

/************ VARIABLES ************/


@pagination-color:                     @text-color;
@pagination-bg:                        #fff;
@pagination-border:                    #fff;

@pagination-hover-color:               @color3;
@pagination-hover-bg:                  #fff;
@pagination-hover-border:              @color3;

@pagination-active-color:              @color3;
@pagination-active-bg:                 #fff;
@pagination-active-border:             @color3;

@pagination-disabled-color:            @gray-light;
@pagination-disabled-bg:               #fff;
@pagination-disabled-border:           #ddd;

/************ STYLES ************/


.pagination {
  display: inline-block;
  padding-left: 0;
  margin: @line-height-computed 0;
  border-radius: @border-radius-base;

  > li {
    display: inline-block; // Remove list-style and block-level defaults
    > a,
    > span {
      position: relative;

     // padding: @padding-base-vertical @padding-base-horizontal;
     display: inline-block;
      line-height: @line-height-base;
      text-decoration: none;
      color: @pagination-color;
      background-color: @pagination-bg;
      border: 1px solid @pagination-border;
      width: 30px;
      text-align: center;
      line-height: 30px;
      height: 30px;
      border-radius: 100%;

      .icomoon{
        font-size: 30px;
      }
      &.pager_current_page{
         color: @pagination-hover-color;
        background-color: @pagination-hover-bg;
        border-color: @pagination-hover-border;
      }
    }
    i { vertical-align: middle; }
    /* &:first-child {
      > a,
      > span {
        margin-left: 0;
        .border-left-radius(@border-radius-base);
      }
    }
    &:last-child {
      > a,
      > span {
        .border-right-radius(@border-radius-base);
      }
    } */
  }

  > li > a,
  > li > span {
    &:hover,
    &:focus {
      color: @pagination-hover-color;
      background-color: @pagination-hover-bg;
      border-color: @pagination-hover-border;
    }
  }

  > .active > a,
  > .active > span {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
      cursor: default;
    }
  }

  > .disabled {
    > span,
    > span:hover,
    > span:focus,
    > a,
    > a:hover,
    > a:focus {
      color: @pagination-disabled-color;
      background-color: @pagination-disabled-bg;
      border-color: @pagination-disabled-border;
      cursor: @cursor-disabled;
    }
  }
}

// Sizing
// --------------------------------------------------

// Large
.pagination-lg {
  .pagination-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @border-radius-large);
}

// Small
.pagination-sm {
  .pagination-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @border-radius-small);
}
