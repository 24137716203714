/*	--------------------------------------------------
	--------------------------------------------------
		Agencement des blocs structurants :
	container, header, content, sidebar, footer
--------------------------------------------------
--------------------------------------------------*/

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	z-index: 5;
	background:@white;
	transition:all 0.2s ease;
	box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.15);
   .container {
      position: relative;
      @media (min-width: 1600px) {
         &::after {
            content: "";
            width: 250px;
            height: 100%;
            background-image: url(/images/refonte2022/bg-header.svg);
            position: absolute;
            left: 100%;
            top: 0;
            background-repeat: no-repeat;
        }
      }
   }
}
.header-main{
		position:relative;
		z-index: 0;
		background:@white;
	}
.logo {

}
.sticky{
	position: fixed;
	/* background: @white;; */
	transition:all 0.2s ease;

	.header--boxes{
		/*opacity: 0;
		height: 0;*/
	}
	.navbar-brand{
	}

	.header-main{

		&:after{
			height: 66px;
			background-size: contain;
		}
	}
}
.header--boxes{
     display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    transition:all 0.2s ease;
    margin-bottom: 16px;
}
.page-home{
   header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      transition: top .3s;
      @media (min-width: 992px) {
         top: -210px;
      }
  }
   .diapo-header{
      height: 100vh;
      position: relative;
      @media (min-width: 1200px) {
         height: 950px;
      }
      .cover {
         height: 100%;
         background-color: #000;
         img {
            opacity: .6;
        }
        video {
            object-fit: cover;
            font-family: "object-fit: cover;";
            height: 100%;
            width: 100%;
         }
     }
     .diapo-header-text {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
         padding: 15px;
         .container {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .diapo-header-text-button {
               display: flex;
               flex-direction: column;
               align-items: center;
               justify-content: center;
               border-radius: 50%;
               width: 105px;
               height: 105px;
               min-height: 105px;
               min-width: 105px;
               border: transparent;
               background-color: #fff;
               font-weight: bold;
               color: #707070;
               font-size: 18px;
               margin: 0 0 70px 0;
               transition: transform .1s, background-color .1s;
               @media (max-width: 992px) {
                  display: none;
               }
               &:focus, &:hover {
                  outline: none;
                  transform: scale(1.05);
                  background-color: #e6e4e4;
               }
               &.openMenuHome {
                  opacity: 0;
               }
            }
            @media (max-width: 992px) {
               >p>img {
                  max-width: 160px;
              }
            }
            p {
               color: #fff;
               font-size: 13px;
               display: flex;
               align-items: center;
               margin: 0 0 40px 0;
               @media (min-width: 992px) {
                  font-size: 20px;
               }
               a>img {
                  margin: -5px 0 0 10px;
                  max-height: 160px;
              }
            }
            h1 {
               color: #fff;
               font-size: 40px;
               margin: 0;
               &::after {
                  display: none;
               }
            }
            .diapo-header-text-link {
               display: inline-block;
               background-image: url(/images/refonte2022/tsm-transport-video-presentation.png);
               width: 220px;
               min-width: 220px;
               height: 61px;
               min-height: 61px;
               background-repeat: no-repeat;
               background-position: center;
               background-size: contain;
               overflow: hidden;
               text-indent: -5000px;
               margin: 30px 0 0 0;
               transition: transform .1s;
               @media (min-width: 992px) {
                  width: 368px;
                  min-width: 368px;
                  height: 91px;
                  min-height: 91px;
               }
               &:focus, &:hover {
                  outline: none;
                  transform: scale(1.05);
               }
            }
         }
      }
   }
}
.openMenuHome {
   header {
      top: 0;
   }
   .diapo-header-text-button {
      opacity: 0;
   }
}
/* .header {}

//768px
@media (min-width:@screen-tablet) {
    .affix, .affix-bottom {
        position: fixed!important;
        top:0;
        left:0;
        right:0;
        z-index: 1;
    }
}

.home .header {
    height: 265px;
}

.affix-container {
	height: auto!important; pour contrer le affix
} */

//490px
@media (min-width:@screen-phone) {
	/* .home .header {
	   height: 565px;
	} */
}

//768px
@media (min-width:@screen-tablet) {

}
//992px
@media (min-width:@screen-desktop) {
	/* .home .header {
	    height: 100vh;
	} */
}

//1200px
@media (min-width:@screen-lg-desktop) {
}


/* .home .diapo-header {
    width: 100%;
    height: 100%;
}
.logo, .logo:visited {

}


.header--top {
	width: 100%;
	padding: 10px 0;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;

	// derniere div
	&>div:last-child {
		text-align: right;
	}

}

.home .header--top {
  // 490px
  @media (min-width: @screen-phone) {
 //    &>div:last-child {
	// 	margin-top: -100px;
	// 	width: 24.5%;
	// }
  }
}

.home .diapo-header--img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: -1;
} */

/* Diapo Header */
body:not(.page-home) .diapo-header {
	position: relative;
    height: 250px;
    max-height: 250px;
    width: 100%;
    z-index: 0;
    overflow: hidden;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    top: -56px;
    margin-bottom: -56px;
}
body:not(.page-home) .diapo-header img {
    max-width: none;
    width: 100%;
    position: absolute;
	top: 50%; /* poussé de la moitié de hauteur du référent */
	transform: translateY(-50%); /* tiré de la moitié de sa propre hauteur */
}


/* Breadcrumb */
.breadcrumb-container {
  z-index: 1;
  bottom: -7px;
  position: absolute;
  @media (max-width: @screen-tablet) {
  	left: 0;
  }
}
.content {}

.main {
	position:relative;
	margin-bottom: 50px;
}

.home .main {
	margin-bottom: -10px;
}
.right-bar {}

.footer {
	background-color: @color4;

	@media (min-width:@screen-phone) {
		background: url(/images/refonte2022/tsm-transports-footer.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
   .footer_bottom {
      overflow: hidden;
      background-color: #052c50;
      padding: 12px 0 10px 0;
      ul {
         margin: 0;
      }
  }
}

/* Home 2021 */
.page-home {
   .main {
      overflow: hidden;
   }
}

.linkedin-header {
   background: rgba(255, 255, 255, 0.3);
   text-align: center;
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 1.3rem;
   a {
      text-transform: uppercase;
      color: white;
      &:hover {
         text-decoration: none;
      }
   }
   img {
      margin-left: .3rem;
   }
}

.page-home-header {
   @media (min-width:@screen-tablet) {
      height: 290px;
   }
   //992px
   @media (min-width:@screen-desktop) {
      height: 350px;
   }
   //1200px
   @media (min-width:@screen-lg-desktop) {
      height: 435px;
   }
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   .home-baseline--txt {
      order: inherit;
   }
}
#modalVideo {
   .modal-body {
      padding: 0;
      p {
         margin: 0 !important;
         display: -webkit-flex;
         display: -ms-flex;
         display: flex;
      }
   }
}

.btn-video {
   background: rgba(255,255,255,0.4);
   border: 2px solid #FFF;
   color: #FFF;
   width: 300px;
   margin: 0 auto;
   border-radius: 3rem;
   padding: 0.8rem;
   outline: none !important;
   transition: .3s;
   &:hover {
      background: rgba(32,62,92,0.4);
      border: 2px solid #FFF;
   }
   @media (min-width:@screen-tablet) {
      margin-bottom: 70px;
   }
}

.full-entite-home-refonte {
   h2 {
      text-align: left !important;
      &:after {
         margin: initial !important;
      }
   }
   p {
      line-height: 2rem;
   }
   a {
      color: #ef4230;
   }
}

.filiales {
   p {
      display: flex;
      align-items: center;
      position: relative;
      flex-wrap: wrap;
      justify-content: center;
      img {
         max-width: 195px;
     }
   }
   a {
      border-bottom: 2px solid #FFF;
      transition: .3s;
      height: 50px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      align-items: center;
      margin: 1rem 2rem;
      &:hover {
         border-bottom: 2px solid #ef4230;
      }
   }
}
#choiceContact {
   .modal-content {
      box-shadow: none!important;
      border: none;
   }
   .contact-choice-elements {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
         background: white;
         height: 160px;
         width: 160px;
         border-radius: 50%;
         margin: 0 2rem;
         display: flex;
         align-items: center;
         justify-content: center;
         img {
            max-width: 140px;
            height: auto;
         }
      }
   }
}



.box-carte {
   position: relative;
   @media (min-width:@screen-tablet) {
      height: auto;
   }
   //992px
   @media (min-width:@screen-desktop) {
      height: 350px;
   }
   //1200px
   @media (min-width:@screen-lg-desktop) {
      height: 520px;
   }
   img {
      @media (min-width:@screen-desktop) {
         position: absolute;
      }
   }
   .box-carte-texte {
      margin-top: 1rem;
      background: white;
      box-shadow: 0 0 15px rgba(000, 000, 000,.2);
      z-index: 1;
      position: relative;
      padding: 4rem 6rem;
      color: #333;
      @media (min-width:@screen-desktop) {
         margin-right: -10rem;
         margin-top: 6rem;
      }
      h1 {
         text-align: left;
         font-size: 2.5rem;
         &:after {
            margin: inherit;
            bottom: -38px;
         }
         @media (min-width:@screen-desktop) {
            font-size: 3rem;
         }
      }
   }
}
.full-entite-home {
   min-height: 640px;
   @media (min-width:@screen-tablet) {
      min-height: 540px;
   }
}
.full-entite-ballons {
   @media (min-width:@screen-tablet) {
      position: absolute;
      left: 50%;
      top: 5%;
      justify-content: flex-start;
   }
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   .ballon {
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 1rem;
      @media (min-width:@screen-tablet) {position: absolute;margin: 0;}
      &-red {
         background: rgba(255, 0, 0, 0.5);
      }
      span {
         font-size: 4rem;
         font-family: 'Catamaran';
         line-height: 4rem;
      }
   }

   .palettes {
      width: 255px;
      height: 255px;
      top:0;
      &:before {
         content: '\e921';
         font-family: 'icomoon';
         font-size: 7rem;
         line-height: 5rem;
      }
   }

   .export {
      width: 150px;
      height: 150px;
      top: 270px;
      &:before {
         content: '\e91f';
         font-family: 'icomoon';
         font-size: 5rem;
         line-height: 5rem;
      }
   }

   .camions {
      width: 206px;
      height: 206px;
      top:220px;
      left: 190px;
      &:before {
         content: '\e91e';
         font-family: 'icomoon';
         font-size: 8rem;
         line-height: 5rem;
      }
   }

   .tracabilite {
      width: 180px;
      height: 180px;
      top:0;
      left: 280px;
      .ballon-inner {
         &:before {
            content: '\e922';
            font-family: 'icomoon';
            font-size: 6rem;
            line-height: 7rem;
         }
      }
   }

   .tourdumonde{
      width: 150px;
      height: 150px;
      top:200px;
      left: 420px;
      &:before {
         content: '\e920';
         font-family: 'icomoon';
         font-size: 5rem;
         line-height: 5rem;
      }
   }

}
.bloc-marchandise-refonte {
   h1 {
      text-align: left;
      &:after {
         margin: initial;
      }
   }
}
.e-box {
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex-direction: column;
   margin: 40px 0 0 0;
   @media (min-width:@screen-desktop) {height: 400px;}
   //768px
   @media (min-width:@screen-tablet) {
      flex-direction: row;
   }
   .e-linkedin {
      background: #EEE;
      position: relative;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width:@screen-tablet) {
         width: 50vw;
      }
      img.desk {
         display: none;
         @media (min-width: 1200px) {
            display: block;
            margin: 0 -310px 0 0;
         }
         @media (min-width: 1800px) {
            display: block;
            margin: 0 -620px 0 0;
         }
      }
      p {
         font-size: 33px;
     }
   }
   .e-actus {
      background: url('/images/home2021/bgactus.jpg');
      background-size: cover;
      box-shadow: 0 0 15px rgba(000, 000, 000,.2);
      z-index: 1;
      //768px
      @media (min-width:@screen-tablet) {
         width: 50vw;
      }
   }
}
.blocchiffrecle {
   background-image: url(/images/refonte2022/tsm-transports-chiffres-clefs.jpg);
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
   padding: 110px 0;
   margin: 90px 0 0 0;
   .container {
      display: flex;
      justify-content: space-between;
      .blocchiffrecle_item {
         width: 220px;
         height: 220px;
         border-radius: 50%;
         position: relative;
         text-align: center;
         color: #2B4E6A;
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         line-height: 1;
         font-size: 15px;
         &::before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border: 13px solid #2b4e6a;
            border-radius: 50%;
            opacity: .4;
        }
         .blocchiffrecle_item-img {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 10px 0;
         img {
            max-height: 100%;
         }
         }
         .blocchiffrecle_item-title {
            color: #2B4E6A;
            font-size: 39px;
            margin: 0;
            line-height: 1;
         }
         p {
            margin: 0;
         }
         &:nth-child(2n+2) {
            margin: 230px 0 0 0;
        }
      }
   }
}

@media (max-width: 992px) {
   .blocchiffrecle .container {
      flex-direction: column;
      align-items: center;
   }
   .blocchiffrecle .container .blocchiffrecle_item:nth-child(2n+2) {
      margin: 0;
   }
   .blocchiffrecle .container .blocchiffrecle_item {
      margin: 0 0 30px 0 !important;
   }
   .header .container::after {
      display: none;
   }
   .navbar .logo {
      margin-top: 15px;
   }
   .page-home .diapo-header .diapo-header-text .container h1 {
      font-size: 25px;
   }
}

.blocentite {
   background: url(/images/refonte2022/tsm-transport-notre-entite.jpg);
   position: relative;
   padding: 120px 0;
   background-position: center;
   background-size: cover;
   &::before {
      content: "";
      width: 100%;
      height: 100%;
      background-color: #2b4e6a;
      opacity: .8;
      position: absolute;
      top: 0;
      left: 0;
  }
  .container {
   text-align: center;
   color: #fff;
   position: relative;
   h2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 0 40px 0;
      &::after {
         content: "";
         width: 38px;
         height: 2px;
         background-color: #ef4230;
         margin: 5px 0 0 0;
     }
  }
  a {
      color: #ef4230;
      margin: 60px 0 0 0;
      display: block;
   }
   p {
      width: 550px;
      max-width: 100%;
      margin: 0 auto;
  }
}
}
/* Diapo Header */
.filets-home, .para-map {
   display: none;
   position: absolute;
}
.filets-home {
   left: -200px;
}
@media (min-width:@screen-desktop) {
   .filets-home, .para-map {
      display: block;
      width: 600px;
   }
}
.diapo-header-refonte {
   overflow: visible !important;
   &:before, &:after {
      display: none;
   }
   .para-map {
      right: -30px;
      top: 40px;
      width: 500px;
      height: 500px;
      position: absolute;
   }
}

/* Box actus */
.nouveaute-box {
   display: -webkit-flex;
   display: -ms-flex;
   display: flex;
   flex-direction: column;
   @media (min-width:@screen-tablet) {
      flex-direction: row;
   }
   .description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }
}
.e-actus {
   .boxlastelements {
      margin: 1rem;
      @media (min-width:@screen-tablet) {
         margin: 5rem;
      }
      h2 {
         display: none;
      }
      h3 {
         font-size: 3rem;
         display: -webkit-flex;
         display: -ms-flex;
         display: flex;
         position: relative;
         margin-bottom: 4rem;
         &:after {
            content: "";
            border-bottom: 2px solid #ef4230;
            width: 38px;
            position: absolute;
            bottom: -12px;
            left: 0;
         }
      }
      .picture {
         float: left;
         padding: 2rem;
      }
      hr {
         display: none;
      }
      .LinkIn {
         background: #EEE;
         padding: 1rem 2rem;
         margin-top: 2rem;
         text-align: center;
         display: inline-block;
         text-align: center;
      }
   }
   .all-actus {
      color: #ef4230;
   }
}

.pagefiliale {
   .diapo-header {
      height: initial !important;
      max-height: initial !important;
      margin: 0 !important;
      top: 0 !important;
      img {
         display: none;
     }
     .breadcrumb-container {
      position: relative;
  }
   }
}
	/** RESPONSIVE **/
//768px
@media (min-width:@screen-tablet) {

}
//992px
@media (min-width:@screen-desktop) {

}
//1200px
@media (min-width:@screen-lg-desktop) {
}
